'use client';

// Ensure the component is treated as a client component
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
interface PdfViewerProps {
  fileUrl: string;
}
const Footer = () => {
  // Dynamically get the current year
  const currentYear = new Date().getFullYear();
  return <footer className="w-full bg-gray-900 text-gray-300 py-4" data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-center items-center md:space-x-6 space-y-2 md:space-y-0">
        {/* Links and Separators Section */}
        <div className="flex flex-col justify-center items-center space-y-2 text-xs">
          <div className="flex flex-wrap justify-center items-center space-x-2 text-xs">
            <span className="text-gray-500">|</span>
            <a href="https://www.datacentrix.co.za/uploads/8/3/1/1/83111140/fiop06t15_-_datacentrix_terms_and_conditions_of_sales.pdf" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white hover:underline">
              Terms and Conditions of Sale
            </a>
            <span className="text-gray-500">|</span>
            <a href="http://www.datacentrix.co.za/uploads/8/3/1/1/83111140/lgis02_-_datacentrix_disclaimer_of_liability_information_statement.pdf" className="text-gray-400 hover:text-white hover:underline">
              Disclaimer of Liability
            </a>
            <span className="text-gray-500">|</span>
            <a href="http://www.datacentrix.co.za/uploads/8/3/1/1/83111140/popia_website_disclaimer_2021.pdf" className="text-gray-400 hover:text-white hover:underline">
              POPIA Disclaimer
            </a>
            <span className="text-gray-500">|</span>
            <a href="https://www.datacentrix.co.za/popia-request.html" className="text-gray-400 hover:text-white hover:underline">
              POPIA Request
            </a>
            <span className="text-gray-500">|</span>
            <a href="http://www.datacentrix.co.za/uploads/8/3/1/1/83111140/datacentrix_privacy_policy_2021.pdf" className="text-gray-400 hover:text-white hover:underline">
              Privacy Policy
            </a>
            <a href="http://www.datacentrix.co.za/uploads/8/3/1/1/83111140/lgis01_-_datacentrix_legal_disclaimer_information_statement.pdf" className="text-gray-400 hover:text-white hover:underline">
              Legal Disclaimer
            </a>
            <span className="text-gray-500">|</span>
            <a href="http://www.datacentrix.co.za/uploads/8/3/1/1/83111140/hoop02_-_section_51_manual_for_private_bodies_2021.pdf" className="text-gray-400 hover:text-white hover:underline">
              Section 51 Manual
            </a>
            <span className="text-gray-500">|</span>
            <a href="http://www.datacentrix.co.za/uploads/8/3/1/1/83111140/spit15_-_data_protection_complaints_procedure.pdf" className="text-gray-400 hover:text-white hover:underline">
              Data Protection Complaints Procedure
            </a>
          </div>

          <div className="flex flex-wrap justify-center items-center space-x-2 text-xs"></div>
          <div className="mt-2 md:mt-0 text-xs">
            <p className="text-center text-gray-400">
              © {currentYear}{' '}
              <a href="https://www.datacentrix.co.za/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
                Datacentrix (Pty) Ltd
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>

        {/* Copyright Section */}
      </div>
    </footer>;
};
export default Footer;